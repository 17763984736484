import { Field } from "formik"
import React, { useEffect, useRef, useState } from "react"
import classNames from "classnames"
import Moment from "moment"
const MgcNewDateFieldV2 = ({
  name,
  label,
  errors,
  onChange,
  withoutOffset,
  setFieldValue,
  disabled,
  values,
  hidden,
  prefix,
  childrenAel,
  childrenNbr,
  isErrorSubmitting,
  resetErrorsAfterSubmitWithErrors,
  showErrorOnValidation
}) => {
  const labelClassNames = classNames("", {
    "col-11 offset-1": !withoutOffset,
    "col-12": withoutOffset,
  })
  const OffessetClassNames = classNames("form-group input-icons ", {
    "col-10 offset-1": !withoutOffset,
    "col-11": withoutOffset,
  })
  const checkerClassNames = classNames("fa fa-2x", {
    "fa-times text-danger": errors[name] && (isErrorSubmitting || showErrorOnValidation),
    // "fa-check text-success": !errors[name],
  })

  const inputDateClassNames = classNames("input-date form-control mb-0", {
    disabled: disabled,
    "is-invalid": errors[name] && (isErrorSubmitting || showErrorOnValidation),
  })
  const [day, setDay] = useState()
  const [month, setMonth] = useState()
  const [year, setYear] = useState()
  const [dayChecked, setDayChecked] = useState()
  const [monthChecked, setMonthChecked] = useState()
  const [yearChecked, setYearCheked] = useState()
  const [changed, setChanged] = useState()
  const dayInput = useRef()
  const monthInput = useRef()
  const yearInput = useRef()

  //ComponentDidMount
  useEffect(() => {
    if (values[fieldName]) {
      let date = values[fieldName].split("-")
      setDayChecked(date[2])
      setMonthChecked(date[1])
      setYearCheked(date[0])
      setDay(date[2])
      setMonth(date[1])
      setYear(date[0])
    } else if (childrenNbr >= 0 && values.enfants[childrenNbr].DateNaissance) {
      let val = values.enfants[childrenNbr].DateNaissance
      let date = val.split("-")
      setDayChecked(date[2])
      setMonthChecked(date[1])
      setYearCheked(date[0])
      setDay(date[2])
      setMonth(date[1])
      setYear(date[0])
    }
  }, [])

  useEffect(() => {
    if (dayChecked && monthChecked && yearChecked && yearChecked.length === 4) {
      checkDateCompleted()
    }
  }, [dayChecked, monthChecked, yearChecked])

  function handelChangeDay(val) {
    if (val.length <= 2) {
      setDayChecked(val)
    }
    if (val.length === 0) {
      setFieldValue(fieldName, "")
    } else if (val && val.length === 2) {
      if (val > 31) {
        setDay(31)
        setDayChecked(31)
      } else {
        setDay(val)
        setDayChecked(val)
      }
      monthInput.current.focus()
    } else if (val.length === 1 && val > 3) {
      setDay(0 + "" + val)
      setDayChecked(0 + "" + val)
      monthInput.current.focus()
    }
    resetErrorsAfterSubmitWithErrors(fieldName)
  }

  function handelChangeMonth(val) {
    //saisie du mois sur 1 chiffre
    if (val.length > 0 && val.length < 2 && val !== 0 && val !== "0") {
      const fixedMonth = 0 + "" + val
      setMonth(fixedMonth)
      setMonthChecked(fixedMonth)
    }
    if (val.length <= 2) {
      setMonthChecked(val)
    }
    if (val.length === 0) {
      setFieldValue(fieldName, "")
    } else if (val && val.length === 2) {
      if (val > 12) {
        setMonth(0 + "" + val[0])
        setMonthChecked(0 + "" + val[0])
      } else {
        setMonth(val)
        setMonthChecked(val)
      }
      yearInput.current.focus()
    }
    resetErrorsAfterSubmitWithErrors(fieldName)
  }

  function handelChangeYear(val) {
    if (val.length <= 4) {
      setYearCheked(val)
    }
    if (val.length === 0) {
      setFieldValue(fieldName, "")
    }
    if (val.length === 4) {
      if (val < 1890) {
        setYearCheked("1890")
        setYear("1890")
      } else {
        setYear(val)
        setYearCheked(val)
      }
    }
    resetErrorsAfterSubmitWithErrors(fieldName)
  }

  function checkDateCompleted() {
    const moment = Moment({ y: year, M: month - 1, d: day })
    if (moment.isValid()) {
      if (childrenAel) {
        let wait = async () => {
          await setFieldValue(fieldName, moment.format("YYYY-MM-DD"))
          onChange(moment.format("YYYY-MM-DD"))
        }
        wait()
      }
      if (onChange && !childrenAel) {
        onChange(moment.format("YYYY-MM-DD"))
      } else {
        setFieldValue(fieldName, moment.format("YYYY-MM-DD"))
      }
      resetErrorsAfterSubmitWithErrors(fieldName)
    }
  }
  const fieldName = prefix ? `${prefix}${name}` : name
  return (
    <>
      <div className="form-row " hidden={hidden}>
        <label className={labelClassNames}>{label}</label>
        <div className={OffessetClassNames} id={fieldName}>
          {/* <i className="far fa-calendar-alt icon" />*/}
          <div className={inputDateClassNames}>
            <Field
              onChange={e => {
                handelChangeDay(e.target.value)
              }}
              type="number"
              name="jour"
              className="input-day"
              placeholder="JJ"
              innerRef={dayInput}
              value={dayChecked}
              disabled={disabled}
            />
            /
            <Field
              onChange={e => {
                handelChangeMonth(e.target.value)
              }}
              type="number"
              name="mois"
              className="input-month"
              placeholder="MM"
              innerRef={monthInput}
              value={monthChecked}
              disabled={disabled}
            />
            /
            <Field
              onChange={e => {
                handelChangeYear(e.target.value)
              }}
              type="number"
              name="annee"
              className="input-year"
              placeholder="AAAA"
              innerRef={yearInput}
              value={yearChecked}
              disabled={disabled}
            />
          </div>
          <div className="invalid-feedback">{errors[name]}</div>
        </div>
        <div className="col-1">
          <span className={checkerClassNames} />
        </div>
      </div>
    </>
  )
}

export default MgcNewDateFieldV2
